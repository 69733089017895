<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-6">
        <div class="mb-3">
          <h6 v-if="stackTemplates.length === 0">Stack templates not found</h6>
          <h6 v-else>Stack templates</h6>
        </div>
      </div>
      <div class="card-body px-5 pt-5 pb-2" v-if="stackTemplates.length !== 0">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Version
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Description
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Stack
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Namespace
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Creation
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="stackTemplate in stackTemplates"
                :key="stackTemplate.id"
              >
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ stackTemplate.name }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ stackTemplate.version }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ stackTemplate.description }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ stackTemplate.stack.name }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ stackTemplate.stack.namespace.name }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ stackTemplate.creationDate }}
                  </p>
                </td>
                <td>
                  <button
                    style="
                      padding: 0;
                      border: none;
                      background: none;
                      color: var(--bs-primary);
                      text-decoration: none;
                    "
                    @click="deleteStackTemplate(stackTemplate)"
                  >
                    <i class="bi bi-trash-fill"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)">
                  <i class="bi bi-arrow-left"></i>
                </button>
              </li>
              <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <button class="page-link" @click="changePage(page)">
                  {{ page }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="changePage(currentPage + 1)">
                  <i class="bi bi-arrow-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {},
  data() {
    return {
      stackTemplates: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  mounted() {
    this.loadStackTemplates();
  },
  methods: {
    async deleteStackTemplate(stackTemplate) {
      try {
        const response = await this.$api.delete(
          `/v1/template/${stackTemplate.id}`
        );
        if (response.status === 200) {
          const message =
            response.data?.message || "template delete successfully";
          toast.success(message);
          this.loadStackTemplates();
        } else {
          throw new Error("Unexpected response status");
        }
      } catch (error) {
        // Extraer el mensaje del backend
        const message =
          error.response?.data?.message || "Error deleting template"; // Campo `message` del backend
        toast.error(message); // Mostrar el mensaje en el toast
        console.error("template deleting error:", error);
        this.loadStackTemplates();
      }
    },

    async loadStackTemplates() {
      try {
        const response = await this.$api.get(
          `/v1/template?page=${this.currentPage}&pagesize=${this.pageSize}`
        );
        this.stackTemplates = response.data.stackTemplates || [];
        this.total = response.data.total || 0;
      } catch (error) {
        if (error.response?.status === 403) {
          this.$router.push("/error/403"); // Redirigir a Forbidden
        } else {
          const message =
            error.response?.data?.message || "Error fetching stack templates";
          toast.error(message);
          console.error("Error fetching stack templates:", error);
        }
      }
    },

    changePage(page) {
      if (page < 1 || page > this.totalPages) {
        toast.warning("Invalid page number");
        return;
      }
      this.currentPage = page;
      this.loadSnapshot();
    },
  },
};
</script>
