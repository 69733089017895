<template>
  <div class="py-7 container-fluid">
    <div class="card w-70">
      <div class="card-header pb-0">
        <h6>Add new Schedule Scale</h6>
      </div>
      <div class="card-body px-5 pt-5 pb-2">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Project</label
          >
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="schedule.stack"
          >
            <option disabled>Select project</option>
            <option
              v-for="(name, index) in uniqueNamespaces"
              :key="index"
              :value="stacks.find((s) => s.namespace?.name === name)"
            >
              {{ name }}
            </option>
          </select>
        </div>
        <div class="mb-3" v-if="schedule.stack !== null">
          <label for="exampleFormControlInput1" class="form-label"
            >Stacks</label
          >
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="selectedStack"
          >
            <option disabled>Select stack</option>
            <option
              v-for="(s, index) in filteredStacks"
              :key="index"
              :value="s"
            >
              {{ s.name }}
            </option>
            <option v-if="filteredStacks.length === 0" disabled>
              Not found stacks in namespace {{ schedule.stack.namespace.name }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Cron</label>
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            v-model="schedule.cron"
            value="schedule.cron"
            :style="{ width: inputWidth }"
            placeholder="* * * * * *"
          />
        </div>
        <!-- <div class="mb-3">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              :value="schedule.active"
              v-model="schedule.active"
              id="flexCheckactive"
            />
            <label class="form-check-label" for="flexCheckactive">
              Active
            </label>
          </div>
        </div> -->
        <div class="mb-3">
          <button @click="addSchedule()" type="button" class="btn btn-primary">
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  components: {},
  data() {
    return {
      schedule: {
        stack: null,
        cron: "",
        active: true,
        retainDays: 5,
        namespace: null,
        selectedStack: null,
      },
      stacks: [],
    };
  },
  computed: {
    filteredStacks() {
      if (!this.schedule.stack) {
        return [];
      }
      return this.stacks.filter(
        (s) => s.namespace.name === this.schedule.stack.namespace.name
      );
    },
    uniqueNamespaces() {
      // Obtener todos los nombres de los namespaces únicos
      const namespaces = this.stacks.map((s) => s.namespace?.name);
      // Eliminar duplicados y ordenar alfabéticamente
      return [...new Set(namespaces)].sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    },
  },
  props: ["id"],
  mounted() {
    // this.loadUser();
    this.loadStacks();
  },
  methods: {
    async loadStacks() {
      try {
        const response = await this.$api.get("/v1/stack?nopaginate=1");
        this.stacks = response.data.stacks;
      } catch (error) {
        console.error("Error fetching stack:", error);
      }
    },
    async addSchedule() {
      try {
        const response = await this.$api.post(
          "/v1/schedule/scale",
          this.schedule
        );
        if (response.status >= 200 && response.status < 300) {
          toast.success("Schedule scale create successful");
          this.$router.push({ name: "ScheduleScale" });
        }
      } catch (error) {
        toast.error("Schedule scale create error");
      }
    },
  },
};
</script>
