<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <div class="mb-3">
          <h6 v-if="stacks.length === 0">Stacks not found</h6>
          <h6 v-else>Stacks</h6>
          <router-link
            class="btn btn-primary btn-sm"
            :to="{
              name: 'StackAdd',
            }"
            >Add</router-link
          >
        </div>
      </div>
      <div class="row">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <div class="col-lg-10">
              <div class="row mt-4">
                <div class="row">
                  <div
                    class="col-lg-2 col-md-6 col-12"
                    v-for="stack in stacks"
                    :key="stack.name"
                  >
                    <div class="card m-3 w-100">
                      <div class="card-body">
                        <div class="text-sm mb-1">
                          <i class="bi bi-stack font-weight-bold"
                            >Name: {{ stack.name }}</i
                          >
                        </div>
                        <div class="text-sm mb-1">
                          <i class="bi bi-collection-fill font-weight-bold">
                            Project: {{ stack.namespace.name }}</i
                          >
                        </div>
                        <div class="mb-3">
                          <argon-badge
                            variant="fill"
                            size="md"
                            color="secondary"
                          >
                            {{ stack.timezone }}
                          </argon-badge>
                          <argon-badge
                            variant="fill"
                            size="md"
                            color="secondary"
                          >
                            {{ stack.imageStack.name }}
                          </argon-badge>
                          <argon-badge
                            variant="fill"
                            size="md"
                            color="secondary"
                          >
                            Machine {{ stack.machine.name }} ({{
                              stack.machine.memory
                            }}
                            x {{ stack.machine.cpu }})
                          </argon-badge>
                        </div>
                        <div class="mb-3">
                          <!-- Enlace para editar -->
                          <router-link
                            class="btn btn-primary btn-xs"
                            :to="{
                              name: 'StackUpdate',
                              params: { id: stack.ID },
                            }"
                            title="Edit"
                            ><i class="bi bi-pencil-fill"></i> Edit</router-link
                          >
                          <a
                            href="#"
                            @click="createSnapshot(stack)"
                            class="btn btn-primary btn-xs"
                          >
                            <i class="bi bi-archive-fill"></i>
                            Create snapshot
                          </a>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            :data-bs-target="'#stackTemplate-' + stack.ID"
                            class="btn btn-primary btn-xs"
                          >
                            <i class="bi bi-gear-fill"></i>
                            Create template
                          </a>
                          <div
                            class="modal fade"
                            :id="'stackTemplate-' + stack.ID"
                            tabindex="-1"
                            aria-labelledby="ModalLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog">
                              <form @submit.prevent="createTemplate(stack)">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title" id="stack">
                                      Create new stack template
                                    </h5>
                                  </div>

                                  <div class="modal-body">
                                    <div class="mb-3">
                                      <label
                                        for="exampleFormControlInput1"
                                        class="form-label"
                                        >Name</label
                                      >
                                      <input
                                        required
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="name"
                                        v-model="stackTemplate.name"
                                        :style="{ width: inputWidth }"
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label
                                        for="exampleFormControlInput1"
                                        class="form-label"
                                        >Version</label
                                      >
                                      <input
                                        required
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="name"
                                        v-model="stackTemplate.version"
                                        :style="{ width: inputWidth }"
                                      />
                                    </div>
                                    <div class="mb-3">
                                      <label
                                        for="exampleFormControlTextarea1"
                                        class="form-label"
                                        >Description</label
                                      >
                                      <textarea
                                        class="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                        required
                                        v-model="stackTemplate.description"
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button
                                      type="submit"
                                      data-bs-dismiss="modal"
                                      class="btn btn-primary btn-xs"
                                    >
                                      <i class="bi"></i>
                                      Create
                                    </button>
                                    <a
                                      href="#"
                                      data-bs-dismiss="modal"
                                      class="btn btn-primary btn-xs"
                                    >
                                      <i class="bi"></i>
                                      Cancel
                                    </a>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)">
                  <i class="bi bi-arrow-left"></i>
                </button>
              </li>
              <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <button class="page-link" @click="changePage(page)">
                  {{ page }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="changePage(currentPage + 1)">
                  <i class="bi bi-arrow-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: { ArgonBadge },
  data() {
    return {
      stacks: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      snapshots: [],
      stackTemplate: {
        stack: Object,
        version: "",
        description: "",
        name: "",
      },
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  mounted() {
    this.loadStacks();
  },
  methods: {
    async createTemplate(stack) {
      this.stackTemplate.stack = stack;
      try {
        const response = await this.$api.post(
          "/v1/template",
          this.stackTemplate
        );
        if (response.status === 200) {
          const message =
            response.data?.message || "template created successfully";
          toast.success(message);
          this.stackTemplate.stack = null;
          this.stackTemplate.version = "";
          this.stackTemplate.description = "";
          this.stackTemplate.name = "";
        } else {
          throw new Error("Unexpected response status");
        }
      } catch (error) {
        // Extraer el mensaje del backend
        const message =
          error.response?.data?.message || "Error creating template"; // Campo `message` del backend
        toast.error(message); // Mostrar el mensaje en el toast
        console.error("template creation error:", error);
      }
    },
    async createSnapshot(stack) {
      try {
        const response = await this.$api.post("/v1/snapshot", stack);
        if (response.status === 200) {
          const message =
            response.data?.message || "Snapshot created successfully";
          toast.success(message);
        } else {
          throw new Error("Unexpected response status");
        }
      } catch (error) {
        // Extraer el mensaje del backend
        const message =
          error.response?.data?.message || "Error creating snapshot"; // Campo `message` del backend
        toast.error(message); // Mostrar el mensaje en el toast
        console.error("Snapshot creation error:", error);
      }
    },

    async loadStacks() {
      try {
        const response = await this.$api.get(
          `/v1/stack?page=${this.currentPage}&pagesize=${this.pageSize}`
        );
        this.stacks = response.data.stacks || [];
        this.total = response.data.total || 0;
      } catch (error) {
        if (error.response?.status === 403) {
          this.$router.push("/error/403"); // Redirigir a Forbidden
        } else {
          const message =
            error.response?.data?.message || "Error fetching stacks";
          toast.error(message);
          console.error("Error loading stacks:", error);
        }
      }
    },

    changePage(page) {
      if (page < 1 || page > this.totalPages) {
        toast.warning("Invalid page number");
        return;
      }
      this.currentPage = page;
      this.loadStacks();
    },

    async updateStack(stack) {
      try {
        const response = await this.$api.put("/v1/stack", stack); // Corrigiendo método a PUT si es una actualización
        if (response.status === 200) {
          toast.success("Stack updated successfully");
          this.loadStacks(); // Recargar stacks actualizados
        } else {
          throw new Error("Unexpected response status");
        }
      } catch (error) {
        const message = error.response?.data?.message || "Error updating stack";
        toast.error(message);
        console.error("Error updating stack:", error);
      }
    },
  },
};
</script>
