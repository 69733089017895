<template>
  <div class="card w-50">
    <div class="card-header pb-0">
      <h6>stack</h6>
    </div>
    <div class="card-body px-5 pt-5 pb-2">
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Name</label>
        <input
          required
          type="text"
          class="form-control form-control-sm"
          id="name"
          v-model="stack.name"
          :style="{ width: inputWidth }"
          :readonly="edit === true"
        />
      </div>
      <div v-if="!edit" class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Project</label>
        <select
          required
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="stack.namespace"
          :disabled="edit === true"
        >
          <option disabled>Select project</option>
          <option v-for="(ns, index) in namespaces" :key="index" :value="ns">
            {{ ns.name }}
          </option>
        </select>
      </div>
      <div v-if="!edit && stack.namespace !== ''" class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="stack.createFromTemplate"
          id="flexCheckactive2"
        />
        <label class="form-check-label" for="flexCheckactive">
          Create from template
        </label>
      </div>
      <div v-if="stack.createFromTemplate && !edit" class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Stack templates</label
        >
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="stack.CreateFromTemplateName"
          @focus="loadStackTemplates()"
        >
          <option v-if="filteredStackTemplates.length === 0" disabled selected>
            Not found templates in namespace {{ stack.namespace.name }}
          </option>
          <option v-else disabled selected>Select template</option>
          <option
            v-for="(s, index) in filteredStackTemplates"
            :key="index"
            :value="s.snapshot.name"
          >
            {{ s.version }} {{ s.name }}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Image template</label
        >
        <select
          required
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="stack.imageStack"
        >
          <option disabled>Select image stack</option>
          <option
            v-for="(tag, index) in imagesStacks"
            :key="index"
            :value="tag"
          >
            {{ tag.name }}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Machine type</label
        >
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="stack.machine"
        >
          <option disabled>Select machine</option>
          <option
            v-for="(machine, index) in machines"
            :key="index"
            :value="machine"
          >
            {{ machine.name }} ({{ machine.memory }} x {{ machine.cpu }})
          </option>
        </select>
      </div>

      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Timezone</label
        >
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="stack.timezone"
        >
          <option disabled selected>Select timezone</option>
          <option
            v-for="(tz, index) in timezones.name"
            :key="index"
            :value="tz"
          >
            {{ tz }}
          </option>
        </select>
      </div>
      <div v-if="edit" class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Storage Size (Gi)</label
        >
        <input
          required
          type="text"
          class="form-control form-control-sm"
          id="pvcSize"
          v-model="stack.pvcSize"
          :style="{ width: inputWidth }"
          :readonly="edit === true"
        />
      </div>
      <div v-if="edit" class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Increase storage Size (Gi)</label
        >
        <input
          required
          type="number"
          class="form-control form-control-sm"
          id="pvcNewSize"
          v-model.number="stack.pvcNewSize"
          :style="{ width: inputWidth }"
        />
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="stack.enableSnapshot"
          id="enableSnapshot"
        />
        <label class="form-check-label" for="enableSnapshot">
          Enable auto snapshots by system
        </label>
      </div>
      <div v-if="edit && stack.namespace !== ''" class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="stack.restoreSnapshot"
          id="flexCheckactive"
        />
        <label class="form-check-label" for="flexCheckactive">
          Restore snapshot
        </label>
      </div>

      <div v-if="stack.restoreSnapshot && edit" class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Snapshot</label
        >
        <select
          class="form-select form-select-sm"
          aria-label="Default select example"
          v-model="stack.restoreSnapshotVolumeName"
          @focus="loadSnapshotsStack()"
        >
          <option v-if="!snapshots || snapshots.length === 0" disabled selected>
            Not found snapshots for stack {{ stack?.name || "unknown" }}
          </option>
          <option disabled selected>Select snapshot</option>
          <option v-for="(s, index) in snapshots" :key="index" :value="s.name">
            {{ s.name }}
          </option>
        </select>
        <div v-if="stack.restoreSnapshot" class="alert custom-alert-danger">
          Importante! La acción seleccionada restaurará los datos del snapshot
          en el stack actual. Los datos del stack actual serán eliminados.
        </div>
      </div>
      <div v-if="edit" class="mb-3">
        <button @click="updateStack()" type="button" class="btn btn-primary">
          <span v-if="edit">Update</span>
        </button>
        <button @click="removeStack()" type="button" class="btn btn-danger">
          <span v-if="edit">Remove</span>
        </button>
      </div>
      <div v-else class="mb-3">
        <button @click="addStack()" type="button" class="btn btn-primary">
          <span>Add</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  props: ["id"],
  components: {},
  created() {
    if (this.id !== undefined && this.id !== null && this.id !== "") {
      this.loadStack(this.id);
      this.edit = true;
    }
  },
  data() {
    return {
      edit: false,
      stack: {
        name: "",
        namespace: "",
        timezone: "",
        imageStack: Object,
        machine: Object,
        restoreSnapshot: false,
        createFromTemplate: false,
        CreateFromTemplateName: "",
        restoreSnapshotVolumeName: "",
        enableSnapshot: true,
        pvcSize: 0,
        pvcNewSize: 0,
      },
      generateNewPassword: false,
      namespaces: [],
      imagesStacks: [],
      resources: [],
      timezones: [],
      snapshots: [],
      stackTemplates: [],
      machines: {},
    };
  },
  computed: {
    filteredStackTemplates() {
      return this.stackTemplates.filter(
        (s) => s.stack.namespace.name === this.stack.namespace.name
      );
    },
  },
  mounted() {
    this.loadNamespaces();
    this.loadImageStack();
    this.loadTimezones();
    this.loadMachines();
  },
  methods: {
    async loadSnapshotsStack() {
      try {
        const response = await this.$api.get(`/v1/snapshot/stack/${this.id}`);
        this.snapshots = response.data.snapshots || [];
      } catch (error) {
        console.error("Error fetching snapshots:", error);
        toast.error("Failed to load snapshots");
      }
    },
    async loadStack(id) {
      try {
        const response = await this.$api.get(`/v1/stack/${id}`);
        this.stack = response.data || {};
      } catch (error) {
        console.error("Error fetching stack:", error);
        toast.error("Failed to load stack");
      }
    },

    async removeStack() {
      try {
        const response = await this.$api.delete(`/v1/stack/${this.id}`);
        if (response.status >= 200 && response.status < 300) {
          toast.success("Stack deleted successfully");
          this.$router.push({ name: "Stack" }); // Redirigir a la página de stacks
        }
      } catch (error) {
        const message = error.response?.data?.message || "Stack delete error";
        toast.error(message);
        console.error("Error deleting stack:", error);
      }
    },

    async loadNamespaces() {
      try {
        const response = await this.$api.get("/v1/namespace");
        this.namespaces = response.data || [];
      } catch (error) {
        console.error("Error fetching namespaces:", error);
        toast.error("Failed to load namespaces");
      }
    },

    async loadImageStack() {
      try {
        const response = await this.$api.get("/v1/image");
        this.imagesStacks = response.data || [];
      } catch (error) {
        console.error("Error fetching image stacks:", error);
        toast.error("Failed to load image stacks");
      }
    },

    async loadMachines() {
      try {
        const response = await this.$api.get("/v1/utils/machines");
        this.machines = response.data || [];
      } catch (error) {
        console.error("Error fetching machines:", error);
        toast.error("Failed to load machines");
      }
    },

    async loadTimezones() {
      try {
        const response = await this.$api.get("/v1/utils/timezone");
        this.timezones = response.data || [];
      } catch (error) {
        console.error("Error fetching timezones:", error);
        toast.error("Failed to load timezones");
      }
    },

    async loadSnapshots() {
      try {
        const response = await this.$api.get("/v1/snapshot?nopaginate=1");
        this.snapshots = response.data.snapshots || [];
      } catch (error) {
        console.error("Error fetching snapshots:", error);
        toast.error("Failed to load snapshots");
      }
    },

    async loadStackTemplates() {
      try {
        const response = await this.$api.get("/v1/template?nopaginate=1");
        this.stackTemplates = response.data.stackTemplates || [];
      } catch (error) {
        console.error("Error fetching templates:", error);
        toast.error("Failed to load stack templates");
      }
    },

    async addStack() {
      try {
        const response = await this.$api.post("/v1/stack", this.stack);
        if (response.status >= 200 && response.status < 300) {
          toast.success("Stack created successfully");
          this.$router.push({ name: "Stack" });
        }
      } catch (error) {
        const message =
          error.response?.data?.message || "Failed to create stack";
        toast.error(message);
        console.error("Error creating stack:", error);
      }
    },

    async updateStack() {
      try {
        const response = await this.$api.put(
          `/v1/stack?generateNewPassword=${this.generateNewPassword}`,
          this.stack
        );
        if (response.status >= 200 && response.status < 300) {
          toast.success("Stack updated successfully");
          this.stack.restoreSnapshot = false;
          this.$router.push({ name: "Stack" });
        }
      } catch (error) {
        const message =
          error.response?.data?.message || "Failed to update stack";
        toast.error(message);
        console.error("Error updating stack:", error);
      }
    },
  },
};
</script>
