import axios from "axios"; // Importar axios para usar la configuración global
import store from "@/store"; // Importar el store de Vuex

export function createWebSocket(path) {
  const baseURL = axios.defaults.baseURL.replace("http", "ws");
  const token = store.state.auth.token;

  // Agregar el token como parámetro de consulta
  const socketURL = `${baseURL}${path}&token=${encodeURIComponent(token)}`;

  return new WebSocket(socketURL);
}