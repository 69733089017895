<template>
  <div class="py-7 container-fluid">
    <div class="card">
      <div class="card-header pb-6">
        <div class="mb-3">
          <h6 v-if="snapshots.length === 0">Snapshots not found</h6>
          <h6 v-else>Snapshots</h6>
        </div>
      </div>
      <div class="card-body px-5 pt-5 pb-2" v-if="snapshots.length !== 0">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  #
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Stack
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Namespace
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Action
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Creation
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Expire days
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="snapshot in snapshots" :key="snapshot.id">
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ snapshot.id }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ snapshot.name }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ snapshot.stack.name }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ snapshot.stack.namespace.name }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ snapshot.action }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ snapshot.creationDate }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ snapshot.expireDays }}
                  </p>
                </td>
                <td>
                  <button
                    style="
                      padding: 0;
                      border: none;
                      background: none;
                      color: var(--bs-primary);
                      text-decoration: none;
                    "
                    @click="deleteSnapshot(snapshot)"
                  >
                    <i class="bi bi-trash-fill"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="changePage(currentPage - 1)">
                  <i class="bi bi-arrow-left"></i>
                </button>
              </li>
              <li
                class="page-item"
                v-for="page in totalPages"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <button class="page-link" @click="changePage(page)">
                  {{ page }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="changePage(currentPage + 1)">
                  <i class="bi bi-arrow-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {},
  data() {
    return {
      snapshots: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  mounted() {
    this.loadSnapshot();
  },
  methods: {
    async deleteSnapshot(snapshot) {
      try {
        const response = await this.$api.delete(`/v1/snapshot/${snapshot.id}`);
        if (response.status >= 200 && response.status < 300) {
          toast.success("Snapshot deleted successfully");
          this.loadSnapshot(); // Actualizar lista tras eliminar
        } else {
          throw new Error("Unexpected response status");
        }
      } catch (error) {
        const message =
          error.response?.data?.message ||
          "Error occurred while deleting snapshot.";
        toast.error(message);
        console.error("Snapshot delete error:", error);
      }
    },

    async loadSnapshot() {
      try {
        const response = await this.$api.get(
          `/v1/snapshot?page=${this.currentPage}&pagesize=${this.pageSize}`
        );
        if (response.status === 200) {
          this.snapshots = response.data.snapshots || [];
          this.total = response.data.total || 0;
        } else {
          throw new Error("Unexpected response status");
        }
      } catch (error) {
        if (error.response?.status === 403) {
          this.$router.push("/error/403"); // Redirigir a Forbidden
        } else {
          const message =
            error.response?.data?.message || "Error fetching snapshots.";
          toast.error(message);
          console.error("Error loading snapshots:", error);
        }
      }
    },

    changePage(page) {
      if (page < 1 || page > this.totalPages) {
        toast.warning("Invalid page number");
        return;
      }
      this.currentPage = page;
      this.loadSnapshot(); // Cargar nueva página
    },
  },
};
</script>
